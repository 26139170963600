'use client'

import * as Tooltip from '@radix-ui/react-tooltip'
import { ReactNode } from 'react'

const DashboardPriorityTooltip = ({ children }: { children: ReactNode }) => {
  return (
    <Tooltip.Provider delayDuration={0}>
      <Tooltip.Root>
        <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
        <Tooltip.Portal>
          <Tooltip.Content side="bottom" sideOffset={3}>
            <div className="bg-navbar text-white text-sm rounded-sm py-1 px-4 w-[30rem] flex flex-col gap-4">
              <p>
                <span className="font-bold">Salesforce Priority:</span>{' '}
                Calculated based on the company rating in Salesforce and how
                often you&apos;ve emailed them over the past weeks.
              </p>
              <p>
                <span className="font-bold">Algorithmic Priority:</span>{' '}
                Priority calculated based on signals such as headcount growth
                and website traffic changes.
              </p>
            </div>
            <Tooltip.Arrow className="fill-battery-triangle" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default DashboardPriorityTooltip

'use client'

import {
  getDashboardSettings,
  updateDashboardCoverageFilterSetting,
  updateDashboardPrioritySetting,
} from '@/app/(root)/profile/actions'
import { DashboardCoverageFilter, DashboardCoveragePriority } from '@/types'
import {
  ChevronDownIcon,
  QuestionMarkCircleIcon,
} from '@heroicons/react/24/solid'
import { Input, Loader } from '@mantine/core'
import { notifications } from '@mantine/notifications'
import { ChangeEvent, useEffect, useState } from 'react'
import DashboardPriorityTooltip from './DashboardPriorityTooltip'

export default function DashboardSettingsModal() {
  const [loading, setLoading] = useState(false)
  const [dashboardPriority, setDashboardPriority] = useState('')
  const [dashboardCoverageFilter, setDashboardCoverageFilter] = useState('')
  const [dashPriorityLoading, setDashPriorityLoading] = useState(false)
  const [dashCoverageFilterLoading, setDashCoverageFilterLoading] =
    useState(false)

  useEffect(() => {
    async function getSettings() {
      setLoading(true)
      const settings = await getDashboardSettings()

      setLoading(false)
      setDashboardPriority(settings.dashboardPriority)
      setDashboardCoverageFilter(settings.dashboardCoverageFilter)
    }

    getSettings()
  }, [])

  const handleDashboardPriorityUpdate = async (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    try {
      setDashPriorityLoading(true)
      await updateDashboardPrioritySetting(
        e.currentTarget.value as DashboardCoveragePriority
      )
    } catch (error) {
      notifications.show({
        title: 'Failed to update dashboard priority',
        message: '',
        color: 'red',
      })
    } finally {
      setDashPriorityLoading(false)
    }
  }

  const handleDashboardCoverageFilterUpdate = async (
    e: ChangeEvent<HTMLSelectElement>
  ) => {
    try {
      setDashCoverageFilterLoading(true)
      await updateDashboardCoverageFilterSetting(
        e.currentTarget.value as DashboardCoverageFilter
      )
    } catch (error) {
      notifications.show({
        title: 'Failed to update dashboard coverage filter',
        message: '',
        color: 'red',
      })
    } finally {
      setDashCoverageFilterLoading(false)
    }
  }

  if (loading) {
    return (
      <div className="flex justify-center">
        <Loader color="rgb(0, 42, 58)" type="dots" size={16} />
      </div>
    )
  }

  return (
    <div className="flex flex-col gap-2 items-center">
      <div className="flex gap-4 items-center">
        <Input.Wrapper
          label={
            <div className="flex items-center gap-2">
              <p>Dashboard Priority</p>
              <DashboardPriorityTooltip>
                <QuestionMarkCircleIcon className="h-4 inline-block" />
              </DashboardPriorityTooltip>
            </div>
          }
        >
          <Input
            component="select"
            className="w-[15rem]"
            pointer
            rightSection={<ChevronDownIcon className="h-4 w-4" />}
            defaultValue={dashboardPriority}
            onChange={handleDashboardPriorityUpdate}
          >
            <option value="priority">Salesforce Priority</option>
            <option value="algo_priority">Algorithmic Priority</option>
          </Input>
        </Input.Wrapper>
        {dashPriorityLoading && (
          <Loader color="rgb(0, 42, 58)" type="dots" size={24} />
        )}
      </div>
      <div className="flex gap-4 items-center">
        <Input.Wrapper label="Dashboard Coverage Filter">
          <Input
            component="select"
            className="w-[15rem]"
            pointer
            rightSection={<ChevronDownIcon className="h-4 w-4" />}
            defaultValue={dashboardCoverageFilter}
            onChange={handleDashboardCoverageFilterUpdate}
          >
            <option value="all">All</option>
            <option value="platform">Platform</option>
            <option value="add_on_candidates">Add On Candidates</option>
          </Input>
        </Input.Wrapper>
        {dashCoverageFilterLoading && (
          <Loader color="rgb(0, 42, 58)" type="dots" size={24} />
        )}
      </div>
    </div>
  )
}
